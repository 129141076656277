@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import './styles/toast.scss';
@import './custom-theme.scss';

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-color: 163, 88, 207
}

html, body { height: 100%; }
body { margin: 0; font-family: Poppins, "Helvetica Neue", sans-serif; }

.error {
    display: block;
    color: red;
    margin-block: 8px;
}

.button-primary {
    color: white;
    background-color: rgb(var(--primary-color));
    border: rgb(var(--primary-color));

    &:hover {
        background-color: #8a4cbf;
        border: #8a4cbf;
    }
}

.button-secondary {
    color: rgb(var(--primary-color));
    background-color: transparent;
    border: 1px solid rgb(var(--primary-color));

    &:hover {
        color: white;
        background-color: rgb(var(--primary-color));
        border: 1px solid #8a4cbf;
    }
}

.mobile-container {
    max-width: 445px;
    margin: auto;
    padding: 20px 1rem;
}

.pointer {
    cursor: pointer;
    padding: 4px;
    border-radius: 2px;
    
    &:hover {
        background-color: #f1f1f1;
    }
}
